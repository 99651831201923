"use client";

import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
import { IS_PRODUCTION } from "@/lib/config/env";

export default function ErrorPage({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error);
  }, [error]);

  if (!IS_PRODUCTION) {
    return error;
  }

  return (
    <div>
      <h2>Something went wrong!</h2>
    </div>
  );
}
